.career-main {
    margin-top: 2rem;
}

.career-main .description {
    position: relative !important;
    width: 100%;
    height: 50rem;
    background-color: rgba(249, 249, 249, 1);
}

@media screen and (max-width:536px) {
    .career-main .description {
        height: 80rem;
    }

    .career-main .description .career-internship h1 {
        text-align: center;
        font-size: 5rem !important;
        width: 100%;
        margin-left: 0 !important;
    }

    .career-main .description .career-internship p {
        width: 75% !important;
        font-size: 2.5rem !important;
        margin-top: 12rem !important;
    }
}

@media screen and (max-width:480px) {
    .career-main .description {
        height: 88rem;
    }
}

.career-main .description .career-internship {
    margin-left: 8rem;
}

.career-main .description .career-internship h1 {
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    position: absolute;
    font-size: 5rem;
    margin-top: 5rem;
    margin-left: 2%;
}

.career-main .description .career-internship p {
    font-family: 'Poppins', sans-serif;
    position: absolute;
    text-align: justify;
    font-weight: 500;
    font-size: 1.5rem;
    width: 50%;
    line-height: 2.5rem;
    margin-top: 15rem;
    margin-left: 2%;
}

@media screen and (max-width:767px) {
    .career-main .description .career-internship h1 {
        font-size: 3rem;
    }
}

.career-main .description .career-enquiry {
    margin-left: 5rem !important;
}

.career-main .description .career-enquiry .form-data {
    position: absolute;
    padding: 2rem;
    top: 4rem;
    margin-left: 60% !important;
    /* height: 35rem; */
    /* width: 35rem; */
    background-color: white;
    border: 1px solid gray;
}

@media screen and (max-width:651px) {
    .career-main .description .career-enquiry .form-data {
        margin-left: 54% !important;
    }
}

@media screen and (max-width:533px) {

    .career-main .description .career-enquiry .form-data {
        margin-left: 25% !important;
        margin-top: 35rem;
    }
}

.career-main .description .career-enquiry .form-data h1 {
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    font-size: 2rem;
    color: black;
}

.career-main .description .career-enquiry .form-data input,
.career-main .description .career-enquiry .form-data textarea {
    color: black;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 1.5rem;
    padding: 0.5rem;
    letter-spacing: .1rem;
    border-color: red;
}

.career-main .description .career-enquiry .form-data-name,
.career-main .description .career-enquiry .form-data-email,
.career-main .description .career-enquiry .form-data-subject {
    width: 100%;
    height: 3rem;
}

.career-main .description .career-enquiry .form-data-message {
    width: 100%;
}

.career-main .description .career-enquiry .form-data-email,
.career-main .description .career-enquiry .form-data-message,
.career-main .description .career-enquiry .form-data-subject {
    margin-top: 1rem;
}

.career-main .description .career-enquiry .form-data-button {
    height: 4rem;
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    font-size: 1.5rem;
    letter-spacing: .1rem
}

.career-main .description .career-enquiry .form-data ::placeholder {
    color: black;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    line-height: 3rem;
    font-size: 1.5rem;
    letter-spacing: .05rem;
}

@media screen and (max-width:536px) {

    .career-main .description {
        height: 90rem;
    }

    .career-main .description .career-enquiry .form-data {
        height: 48rem;
        width: 40rem;
    }

    .career-main .description .career-enquiry .form-data h1 {
        font-size: 2.5rem !important;
    }

    .career-main .description .career-enquiry .form-data input,
    .career-main .description .career-enquiry .form-data textarea {
        font-size: 2.5rem !important;
        padding: 2rem;
    }

    .career-main .description .career-enquiry .form-data-name,
    .career-main .description .career-enquiry .form-data-email,
    .career-main .description .career-enquiry .form-data-subject {
        width: 100%;
        height: 3rem;
    }

    .career-main .description .career-enquiry .form-data-message {
        width: 100%;
    }

    .career-main .description .career-enquiry .form-data-email,
    .career-main .description .career-enquiry .form-data-message,
    .career-main .description .career-enquiry .form-data-subject {
        margin-top: 1rem;
    }

    .career-main .description .career-enquiry .form-data-button {
        height: 5rem !important;
        font-size: 2.5rem !important;
    }

    .career-main .description .career-enquiry .form-data ::placeholder {
        font-size: 2.5rem !important;
    }
}

.career-main .job-opening-section {
    margin-top: 3rem;
}

.career-main .job-opening-section .career-heading h1 {
    font-family: 'Poppins', sans-serif;
    text-align: center;
    color: black;
    font-weight: 700;
    font-size: 4rem;
}

@media screen and (max-width:480px) {
    .career-main .job-opening-section .career-heading h1 {
        font-size: 6rem !important;
    }
}

.career-main .job-opening-section .career-filtering {
    margin-top: 5rem;
}

.career-main .job-opening-section .career-filtering nav {
    border: 1px solid gray;
    width: 99%;
    margin-left: 1rem;
}

.career-main .job-opening-section .career-filtering .active {
    color: rgba(225, 85, 84, 1);
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    font-size: 2.3rem !important;
}

.career-main .job-opening-section .career-filtering nav button {
    font-family: 'Poppins', sans-serif;
    font-weight: 200;
    font-size: 2rem !important;
    color: black;
}

@media screen and (max-width:480px) {
    .career-main .job-opening-section .career-filtering .active {
        font-size: 3.8rem !important;
    }
    
    .career-main .job-opening-section .career-filtering nav button {
        font-size: 3.5rem !important;
    }
}

/* .career-main .job-opening-section .career-filtering nav .developer {
    margin-left: 5rem;
}

.career-main .job-opening-section .career-filtering nav .marketing {
    margin-left: -25rem;
}

.career-main .job-opening-section .career-filtering nav .designer {
    margin-left: -25rem;
} */

.career-main .job-opening-section .career-filtering .career-carousel {
    width: 80%;
    margin-left: 10%;
}

.career-main .job-opening-section .career-filtering .career-carousel .career-cards {
    width: 33rem !important;
    height: 30rem !important;
    margin-top: 5rem !important;
}

.career-main .job-opening-section .career-filtering .career-carousel .career-cards:hover {
    width: 38rem !important;
    height: 36rem !important;
    margin-top: 2rem !important;
}

.career-main .job-opening-section .career-filtering .career-carousel .career-button {
    width: 100%;
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    color: white;
    font-size: 1.5rem;
    height: 5rem;
    background-color: rgba(175, 175, 175, 1);
}

.career-main .job-opening-section .career-filtering .career-carousel .career-cards .carousel-main {
    width: 7rem;
    height: 7rem;
}

.career-main .job-opening-section .career-filtering .career-carousel .career-cards svg {
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 2rem;
    margin-right: 2rem;
    color: rgba(225, 85, 84, 1);
}

.career-main .job-opening-section .career-filtering .career-carousel .career-cards h1 {
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    line-height: 3rem;
    font-size: 2.5rem;
    text-align: center;
}

.career-main .job-opening-section .career-filtering .career-carousel .career-cards h2 {
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    line-height: 3rem;
    font-size: 2rem;
    margin-top: 4rem;
    margin-left: 1rem;
}


.career-main .job-opening-section .career-filtering .career-carousel .career-cards .p2,
.career-main .job-opening-section .career-filtering .career-carousel .career-cards .p1 {
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: 1.3rem;
    margin-top: 1rem;
    margin-left: 1rem;
}

@media screen and (max-width:480px) {
    .career-main .job-opening-section .career-filtering .career-carousel .career-cards {
        width: 40rem !important;
        height: 40rem !important;
        margin-top: 5rem !important;
    }
    
    .career-main .job-opening-section .career-filtering .career-carousel .career-cards:hover {
        width: 45rem !important;
        height: 42rem !important;
        margin-top: 4rem !important;
    }
    
    .career-main .job-opening-section .career-filtering .career-carousel .career-cards .carousel-main {
        width: 7rem;
        height: 7rem;
    }
    
    .career-main .job-opening-section .career-filtering .career-carousel .career-cards svg {
        font-size: 3rem !important;
    }
    
    .career-main .job-opening-section .career-filtering .career-carousel .career-cards h1 {
        font-size: 3.5rem !important;
        text-align: center;
    }
    
    .career-main .job-opening-section .career-filtering .career-carousel .career-cards h2 {
        font-size: 2.8rem !important;
        margin-top: 5rem !important;
        margin-left: 1rem;
    }
    
    
    .career-main .job-opening-section .career-filtering .career-carousel .career-cards .p2,
    .career-main .job-opening-section .career-filtering .career-carousel .career-cards .p1 {
        font-size: 2.8rem !important;
        margin-top: 1rem;
        margin-left: 1rem;
    }
    
    .career-main .job-opening-section .career-filtering .career-carousel .career-button {
        font-size: 2.6rem !important;
        margin-top: 3rem !important;
    }
}

.career-main .job-opening-section .career-filtering .career-carousel .career-cards:hover .career-button {
    background-color: rgba(225, 85, 84, 1);
}

.career-main .job-opening-section .career-filtering .career-carousel .slick-prev:before,
.career-main .job-opening-section .career-filtering .career-carousel .slick-next:before {
    font-family: 'slick';
    font-size: 2rem;
    line-height: 0.1rem;
    opacity: .75;
    color: black;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}


.career-main .job-opening-section .career-filtering .career-carousel .slick-prev:before {
    position: absolute;
    margin-left: -5rem !important;
    margin-top: 0rem !important;
}

.career-main .job-opening-section .career-filtering .career-carousel .slick-next:before {
    position: absolute;
    margin-left: 0rem !important;
    margin-top: 0rem !important;
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    background-color: white;
}

.career-main .available-positions {
    width: 99%;
    margin-top: 8rem;
}

.career-main .available-positions h1 {
    font-family: 'Poppins', sans-serif;
    text-align: center;
    color: black;
    font-weight: 700;
    font-size: 4rem;
}

.career-main .available-positions .position {
    width: 35rem;
}

@media screen and (max-width:833px) {
    .career-main .job-opening-section .career-filtering .career-carousel .slick-next:before {
        margin-left: -10% !important;
    }

    .career-main .job-opening-section .career-filtering .career-carousel .slick-prev:before {
        margin-left: 0 !important;
    }
}

.career-text-dark{
    font-size: 2rem !important;
}

.career-form-control{
    font-size: 2rem;
    border-color: black;
}

.modal-content{
    margin-top: -1.5rem;
}

.modal-footer button{
    font-size: 2rem;
}

@media screen and (max-width:480px) {
    .career-text-dark{
        font-size: 3rem !important;
    }
    
    .career-form-control{
        font-size: 3rem;
        border-color: black;
    }
    
    .modal-content{
        margin-top: 0rem;
    }
    
    .modal-footer button{
        font-size: 3rem;
    }
}