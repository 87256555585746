/* html {
  font-size: 62.5%; */
/* font-size: 10px; */
/* } */

/* @media all and (max-width: 600px) {
  html { */
/* font-size: 37.5%; */
/* font-size: 6px; */
/* }
} */

/* @media all and (max-width: 767px) {
  html {
    font-size: 37.5%; */
/* font-size: 6px; */
/* }
} */


/* Default font size for larger screens */
html {
  font-size: 62.5%;
  /* 1rem = 10px, 62.5% of 16px = 10px */
}

@media all and (min-width: 1400px) and (max-width:2000px) {
  html {
    font-size: 90% !important;
    /* 1rem = 5px, 31.25% of 16px = 5px */
  }
}

@media all and (max-width: 479px) {
  html {
    font-size: 31.25%;
    /* 1rem = 5px, 31.25% of 16px = 5px */
  }
}

@media all and (min-width: 480px) and (max-width: 767px) {
  html {
    font-size: 43.75%;
    /* 1rem = 7px, 43.75% of 16px = 7px */
  }
}

/* Additional breakpoints and adjustments */
@media all and (min-width: 768px) and (max-width: 991px) {
  html {
    /* font-size: 50%; */
    font-size: 45%;
    /* 1rem = 8px, 50% of 16px = 8px */
  }
}

@media all and (min-width: 992px) and (max-width: 1199px) {
  html {
    /* font-size: 56.25%; */
    font-size: 50%;
    /* 1rem = 9px, 56.25% of 16px = 9px */
  }
}

@media all and (min-width: 1200px) {
  html {
    font-size: 62.5%;
    /* Reset to default for larger screens */
  }
}

.App {
  /* width: 134.94rem; */
  width: 100%;
  overflow-y: hidden;
  scroll-behavior: smooth
}

.btn-whatsapp-pulse {
	background: #25d366;
	color: white;
	position: fixed;
	bottom: 20px;
	right: 20px;
	font-size: 32px;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 0;
	height: 0;
	padding: 24px;
	text-decoration: none;
	border-radius: 50%;
	animation-name: pulse;
  z-index: 1;
	animation-duration: 1.5s;
	animation-timing-function: ease-out;
	animation-iteration-count: infinite;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(37, 211, 102, 0.5);
  }
  80% {
    box-shadow: 0 0 0 14px rgba(37, 211, 102, 0);
  }
}

/* Default 100% is 16px. All rem will adapt accordingly if user changes browser font size. */
/* So now we will convert all px into rem i.e if 30px then 3 rem */

.Toastify__toast-body {
  font-size: 1.5rem !important;
}