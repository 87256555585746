.footer-main {
    /* height: 80rem; */
    /* padding: 1rem; */
    width: 100%;
    height: 100%;
    overflow: hidden;
    /* background-color: rgba(241, 241, 241, 1); */
}

.footer-main .div-1 {
    /* height: 100%; */
    padding: 1rem;
    background-color: rgba(255, 85, 84, 1);
    position: relative;
}

.footer-main .div-1 h1 {
    color: white;
    /* line-height: 80rem; */
    /* margin-left: 10%; */
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    font-size: 2.5rem;
}

@media screen and (max-width:793px) {
    .footer-main .div-1 h1 {
        font-size: 2rem;
        margin-left: 10%;
    }
}

.footer-main .div-2 {
    padding: 2rem 0 0.5rem 0;
    background-color: rgba(241, 241, 241, 1);
}

.footer-main .div-2 .info div {
    margin-top: 3rem;
    position: relative;
    margin-left: 3rem;
}

.footer-main .div-2 .info div .logo {
    width: 9rem;
    position: absolute;
}

.footer-main .div-2 .info div .contact-logo {
    left: -7.5rem;
    height: 7rem;
    top: -2rem;
}

.footer-main .div-2 .info div .email-logo {
    left: -7.5rem;
    height: 7rem;
    top: 6rem;
}

.footer-main .div-2 .info div .location-logo {
    left: -7.5rem;
    height: 7rem;
    top: 13.5rem;
}

.footer-main .div-2 .info div .contact-email {
    margin-top: 5rem !important;
}

.footer-main .div-2 .info div .contact-location {
    margin-top: 5rem !important;
}

.footer-main .div-2 .info div h1 {
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 1.5rem;
}

@media screen and (max-width:993px) {
    .footer-main .div-2 .info div h1 {
        font-size: 2rem;
    }

    .footer-main .div-2 .info div .contact-logo {
        top: -2rem;
    }

    .footer-main .div-2 .info div .email-logo {
        top: 8rem;
    }

    .footer-main .div-2 .info div .location-logo {
        top: 17rem;
    }

    .form-data-1 {
        margin-top: 8rem !important;
    }
}

@media screen and (max-width:991px) {
    .footer-main .div-2 .info div h1 {
        font-size: 2rem;
    }
}

/* .footer-main .div-2 .info,
.footer-main .div-2 .form {
    background-color: rgba(250, 250, 250, 1);
    height: 85%;
    margin-top: 1rem;
} */

.footer-main .div-2 .div-2-main-content {
    padding: 1rem 0;
    background-color: rgba(250, 250, 250, 1);
}

@media screen and (max-width:478px) {
    .footer-main .company-logo-footer {
        width: 70% !important;
    }
}


.form-data-1 {
    padding: 2rem;
    margin-top: 2rem;
    margin-left: 2%;
    width: 70% !important;
    background-color: white;
    border: 1px solid gray;
    border-radius: 35px;
}

@media screen and (max-width:641px) {
    .form-data-1 {
        width: 80% !important;
    }
}

.recaptcha {
    transform:scale(0.85);
    transform-origin:0 0;
}

@media screen and (min-width:1201px) and (max-width:2000px) {
    .recaptcha {
        transform:scale(1.0) !important;
        transform-origin:0 0 !important;
    }
}

@media screen and (max-width:407px) {
    .recaptcha {
        transform:scale(0.75);
        transform-origin:0 0;
    }
}

/* @media screen and (max-width:816px) {
    .form-data-1 {
        padding: 1rem;
        width: 20rem;
        margin-left: 0;
    }
} */

.form-data-1 h1 {
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    font-size: 2.5rem;
    color: black;
}

.form-data-1 input,
.form-data-1 textarea {
    color: black;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 1.5rem;
    padding: 0.98rem;
    letter-spacing: .1rem;
    border-radius: 12px;
}

@media screen and (max-width:993px) {

    .form-data-1 input,
    .form-data-1 textarea {
        font-size: 2rem;
    }

    .form-data-1-button {
        padding: 1rem !important;
    }

    /* .form-data-1-name,
    .form-data-1-email {
        height: 5rem !important;
    } */
}

.form-data-1-name,
.form-data-1-email {
    width: 100%;
    background-color: transparent;
    border-color: red;
    /* height: 3.7rem; */
}

.form-data-1-message {
    width: 100%;
    background-color: transparent;
    border-color: red;
}

.form-data-1-email,
.form-data-1-message {
    margin-top: 1rem;
}

.form-data-1-button {
    /* height: 4rem; */
    padding: 0.5rem;
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    font-size: 1.5rem;
    letter-spacing: .1rem;
    border-radius: 20px;
}

.form-data-1 ::placeholder {
    color: black;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    /* line-height: 3rem; */
    font-size: 1.5rem;
    letter-spacing: .05rem;
}

.footer-main .div-2 .form .subscribe input {
    margin-left: 4rem;
    width: 83%;
    height: 4rem;
    border-radius: 0.8rem;
}

.footer-main .div-2 .form .subscribe h1 {
    margin-left: 4rem;
}

.footer-main .div-2 .form .subscribe button {
    width: 25%;
    height: 3.8rem;
    margin-top: -0.3rem;
    border-radius: 0.8rem;
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    font-size: 1.5rem;
    margin-left: -8rem;
}

.social-media-icons {
    /* margin-left: 1%; */
    margin-top: 1rem;
    width: 72%;
}

@media screen and (max-width:992px) and (min-width:642px) {
    .social-media-icons {
        width: 60%;
    }
}

@media screen and (max-width:641px) {
    .social-media-icons {
        width: 80%;
    }
}

.social-media-icons svg {
    font-size: 3rem !important;
}

.footer-main .footer-copyright-content {
    position: relative;
    padding-top: 1rem;
}

.end-footer svg {
    font-size: 3rem;
}

.end-footer p {
    /* position: absolute; */
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: 2rem;
    /* margin-top: -4rem; */
}

.footer-main .rights {
    /* position: absolute; */
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    font-size: 1.5rem;
    /* margin-top: -4rem; */
    /* margin-left: 70%; */
}

@media screen and (max-width:991px) and (min-width:642px) {
    .form-data-1 {
        width: 60% !important;
    }
}

@media screen and (max-width:993px) {
    .footer-main .div-2 {
        display: grid !important;
        grid-template-columns: repeat(1, 1fr);
    }

    /* .footer-main {
        height: 130rem;
    } */

    /* .footer-main .div-2 .info,
    .footer-main .div-2 .form {
        background-color: transparent;
        height: 85%;
        margin-top: -12rem;
    } */

    /* .form-data-1 {
        padding: 3rem;
        margin-left: 2%;
        width: 100%;
    } */

    .form-data-1 h1 {
        font-size: 3.5rem;
    }

    .form-data-1 ::placeholder {
        font-size: 2rem;
    }

    /* .end-footer p {
        margin-top: -7%;
    }

    .footer-main .rights {
        margin-top: -7%;
    } */

    /* .social-media-icons {
        margin-left: 1%;
        margin-top: -2rem !important;
    } */
}