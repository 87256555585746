.Home-main {
    /* width: 134.94rem; */
    width: 100%;
    overflow-x: hidden;
    overflow-y: hidden;
}

.Home-main-section1 {
    position: relative;
    width: 100%;
}

.homeImage1 {
    width: 100%;
    height: 52rem;
}

.Home-main .gitform {
    /* width: 35rem;
    height: 39.6rem; */
    padding: 2rem;
    background-color: rgba(0, 0, 0, 0.5);
    position: absolute;
    /* left: 80rem; */
    left: 60%;
    top: 10rem;
    z-index: 5;
}

@media screen and (max-width:480px) {
    .Home-main .gitform {
        left: 35% !important;
        width: 45rem;
        /* height: 45rem; */
    }
    .form-data {
        width: 42rem !important;
    }
    .form-data h1 {
        font-size: 5rem;
    }
    
    .form-data input,
    .form-data textarea {
        font-size: 2rem !important;
    }
    
    /* .form-data-name,
    .form-data-email {
        height: 4.7rem !important;
    } */
    
    .form-data-message {
        width: 100%;
    }
    .form-data-button {
        /* height: 4rem; */
        font-size: 1.5rem;
    }
    
    .form-data ::placeholder {
        font-size: 2.5rem !important;
    }
}

@media screen and (max-width:633px) {
    .Home-main .gitform {
        left: 50%;
    }
}

.form-data {
    /* margin-top: 2rem;
    margin-left: 4%; */
    width: 32rem;
}

@media screen and (max-width:363px) {
    .form-data {
        margin-left: 0;
    }
}

.form-data h1 {
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    font-size: 2.5rem;
    color: white;
}

.form-data input,
.form-data textarea {
    color: white;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 1.5rem;
    padding: 0.5rem;
    letter-spacing: .1rem;
}

.form-data-name,
.form-data-email {
    width: 100%;
    background-color: transparent;
    border-color: white;
    /* height: 3.7rem; */
}

.form-data-message {
    width: 100%;
    background-color: transparent;
    border-color: white;
}

.form-data-email,
.form-data-message {
    margin-top: 1rem;
}

.form-data-button {
    /* height: 4rem; */
    padding: 0.8rem;
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    font-size: 1.5rem;
    letter-spacing: .1rem
}

.form-data ::placeholder {
    color: white;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    line-height: 3rem;
    font-size: 1.5rem;
    letter-spacing: .05rem;
}

.section1-motto {
    position: absolute;
    color: black;
    left: 29.5%;
    top: 83.4%;
    width: 50%;
    /* height: 15rem; */
    padding: 1rem;
    /* background-color: rgba(0, 93, 114, 1); */
    background-color: #EDEDED;
}

@media screen and (max-width:404px) {
    .section1-motto{
        width: 60%;
        left: 30%;
    }
}

.section1-motto p {
    font-family: 'Poppins', sans-serif !important;
    font-weight: 200 !important;
    font-size: 3rem;
}

.section2-motto p {
    font-family: 'Poppins', sans-serif !important;
    font-weight: 200 !important;
    font-size: 3rem;
}

.section2-motto {
    position: absolute;
    color: black;
    left: 80%;
    top: 83.4%;
    width: 20%;
    /* height: 15rem; */
    padding: 1rem;
    /* background-color: rgba(0, 93, 114, 1); */
    background-color: #BBBBBB;
}

.about-us-part {
    width: 102%;
    position: relative;
    /* padding: 1rem 0rem; */
    /* margin-top: 1.5% !important; */
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
}

.about-us-part .div-1 {
    /* background-color: rgba(225, 85, 84, 1); */
    background-color: white;
    /* color: white; */
    color: black;
    padding: 0 0 5rem 0;
    margin-top: 5rem;
}

.about-us-part .div-1 div {
    /* margin-top: 15%; */
    margin-left: 10%;
}

.about-us-part .div-1 div h1 {
    font-family: 'Poppins', sans-serif !important;
    font-weight: 700 !important;
    font-size: 5rem;
}

.about-us-part .div-1 div p {
    font-family: 'Poppins', sans-serif !important;
    font-weight: 400 !important;
    text-align: justify;
    font-size: 1.5rem;
    width: 75%;
    letter-spacing: 0.1rem;
    line-height: 2.5rem;
}

.about-us-part .div-1 div button {
    background-color: #E15554;
    color: white;
    padding: 1rem 5rem;
    font-family: 'Poppins', sans-serif !important;
    font-weight: 700 !important;
    font-size: 1.5rem;
}

@media screen and (max-width:283px) {
    .about-us-part .div-1 div p {
        font-size: 2rem;
        width: 85%;
    }
    .about-us-part {
        height: 65rem;
    }
}

@media screen and (max-width:480px) {
    .about-us-part .div-1 div p {
        font-size: 2rem;
        width: 85%;
    }
}

.about-us-part .div-2 {
    /* background-color: rgba(225, 85, 84, 1); */
    /* background: linear-gradient(0.25turn, #3f87a6, #ebf8e1, #f69d3c); */
    background: linear-gradient(0.25turn, white, rgba(235, 235, 235, 0.48), #CBC8C8);
    /* height: 100%;
    padding: 0; */
}

.about-us-part .div-2 img {
    /* position: absolute; */
    /* width: 30%; */
    /* height: 43rem; */
    /* left: 60%; */
    /* top: -3.5%; */
    height: 85%;
    margin-left: 0.8rem;
    margin-top: 2rem;
}

.about-us-part .div-2 .about-us-name {
    font-family: 'Poppins', sans-serif !important;
    font-weight: 500 !important;
    font-size: 1.8rem;
    margin-left: 11rem;
}

@media screen and (min-width:1201px) and (max-width:1400px) {
    .about-us-part .div-2 .about-us-name {
        margin-left: 16rem;
    }

    .about-us-part .div-2 .about-us-designation {
        margin-left: 22rem !important;
    }
}

@media screen and (min-width:1401px) and (max-width:2000px) {
    .about-us-part .div-2 .about-us-name {
        margin-left: 14.5rem !important;
    }

    .about-us-part .div-2 .about-us-designation {
        margin-left: 20.5rem !important;
    }
}

.about-us-part .div-2 .about-us-designation {
    font-family: 'Poppins', sans-serif !important;
    font-weight: 700 !important;
    font-size: 1.8rem;
    margin-left: 17rem;
}

@media screen and (max-width:767px) {
    .about-us-part .div-2 img {
        position:static;
    }
}

.Home-main-section3 {
    width: 100%;
    position: relative;
    top: 2rem;
}

.homeImage3 {
    width: 100%;
    height: 60rem;
}

.Home-main-section3 .get-a-quote {
    /* position: absolute; */
    position: relative;
    width: 100%;
    /* height: 15rem; */
    padding: 8rem 2rem 5rem 2rem;
    /* background-color: #E15554; */
    background: linear-gradient(to right, white 0%, white 9%, #3fb18e 13%, #3fb18e 20%, #E15554 23%, #E15554 100%);
}

@media screen and (max-width:767px) {
    .Home-main-section3 .get-a-quote {
        background: #E15554 !important;
    }
}

.Home-main-section3 .get-a-quote img {
    position: absolute;
    margin-top: -9.5rem;
    height: 27.5rem;
    margin-left: 1rem;
}

/* .gaq-row {
    margin-top: 4.5rem;
    width: 100%;
} */

.gaq-heading h1 {
    /* width: 50rem !important; */
    color: white;
    /* margin-left: 10%; */
    font-family: 'Poppins', sans-serif !important;
    font-weight: 400 !important;
    font-size: 2.5rem;
    line-height: 4rem;
}

.gaq-button button {
    /* background-color: rgba(225, 85, 84, 1) !important; */
    width: 20rem;
    margin-left: 25%;
    height: 4rem;
    font-family: 'Poppins', sans-serif !important;
    font-weight: 700 !important;
    font-size: 1.5rem;
}

.gaq-button .button1{
    background-color: white;
    color: black;
}

.gaq-button .button2{
    /* background-color: rgb(222, 54, 54) !important; */
    background-color: rgb(217, 56, 56) !important;
    color: white;
}

@media screen and (max-width:421px) {
    .gaq-button button {
        width: 20rem;
        margin-left: 45%;
        height: 6rem;
        font-size: 2rem;
    }
    .gaq-heading h1 {
        width: 90% !important;
        margin-left: 10%;
        font-size: 2.5rem;
    }
}

@media screen and (max-width:668px) {
    .gaq-row {
        margin-top: 0rem;
    }
    .gaq-button button {
        width: 20rem;
        margin-left: 30%;
        height: 6rem;
        margin-top: 2rem;
        font-size: 2rem;
    }
    .gaq-heading h1 {
        width: 90% !important;
        margin-left: 10%;
        font-size: 2.5rem;
    }
}


.gaq-text-dark{
    font-size: 2rem !important;
}

.gaq-form-control{
    font-size: 2rem;
    border-color: black;
}

@media screen and (min-width:1401px) and (max-width:2000px) {
    .modal-content{
        margin-top: -1.5rem;
        width: 200%;
        margin-left: -15rem;
    }
}

.modal-content{
    margin-top: -1.5rem;
}

.modal-footer button{
    font-size: 2rem;
}

@media screen and (max-width:480px) {
    .gaq-text-dark{
        font-size: 3rem !important;
    }
    
    .gaq-form-control{
        font-size: 3rem;
        border-color: black;
    }
    
    .modal-content{
        margin-top: 0rem;
    }
    
    .modal-footer button{
        font-size: 3rem;
    }
}

.Home-main-section3 .service-img-part {
    margin-top: 1.5rem;
}

.service-heading h1 {
    color: white;
    position: absolute;
    top: 30%;
    left: 45%;
    font-family: 'Poppins', sans-serif !important;
    font-weight: 700 !important;
    font-size: 5rem;
}

.service-heading .service-carousel {
    position: absolute;
    top: 60%;
    left: 40%;
}

.socialMediaImage {
    height: 30rem;
    z-index: 1;
}

/* .blank-grey-div {
    background-color: rgba(132, 132, 132, 1);
    height: 20rem;
    width: 100%;
    position: absolute;
    top: 93%;
    z-index: -1;
} */

.caro3-next {
    margin-left: 90rem !important;
    z-index: 1;
    position: absolute;
}

.caro3-prev {
    margin-left: -50rem !important;
    z-index: 1;
    position: absolute;
}

/* .Home-main-section4 {
    position: relative;
    margin-top: 0rem;
    height: 32rem;
    background-color: rgba(249, 249, 249, 1);
} */

/* .we-are-experts {
    margin-top: 6rem;
    position: absolute;
    margin-left: 10rem;
}

.we-are-experts h1 {
    font-family: 'Poppins', sans-serif !important;
    font-weight: 700 !important;
    font-size: 4.5rem;
}

.we-are-experts p {
    font-family: 'Poppins', sans-serif !important;
    font-weight: 500 !important;
    font-size: 1.8rem;
    line-height: 2rem;
    width: 48rem;
    margin-top: 3rem;
}

@media screen and (max-width:477px) {
    .we-are-experts p {
        font-size: 2.2rem;
    }
    .Home-main-section4 {
        height: 45rem;
    }
}

@media screen and (max-width:295px) {
    .we-are-experts {
        margin-left: 5%;
    }
} */

.contactUs-banner-section {
    margin-top: 10rem;
    position: relative;
}

@media screen and (max-width:575px) {
    .contactUs-banner-section {
        margin-top: 3rem;
    }
}

.contactUs-banner-section .banner-desc {
    padding: 3rem 7rem;
    background-color: #E15554;
}

.contactUs-banner-section .banner-desc h1{
    font-family: 'Poppins', sans-serif !important;
    font-weight: 700 !important;
    color: white;
    font-size: 4rem;
}

.contactUs-banner-section .banner-desc h2{
    font-family: 'Poppins', sans-serif !important;
    font-weight: 400 !important;
    color: white;
    font-size: 2.5rem;
}

.contactUs-banner-section .banner-desc .btn{
    font-family: 'Poppins', sans-serif !important;
    font-weight: 700 !important;
    font-size: 2rem !important;
    width: 20rem;
    margin-top: 2rem;
}

.contactUs-banner-section .banner-image {
    position: absolute;
    left: 60%;
    top: -29%;
}

.contactUs-banner-section .banner-image img{
    height: 37rem;
}

.Home-main-section5 .technology {
    font-family: 'Poppins', sans-serif !important;
    font-weight: 700 !important;
    font-size: 4rem;
    margin-top: 3rem;
    text-align: center;
}

.carousel-section {
    margin-top: 5rem;
    /* position: relative; */
    width: 100%;
    /* margin-left: 5%; */
    z-index: 1;
}

/* @media screen and (max-width:480px) {
    .carousel-section {
        margin-left: 4rem;
        width: 88%;
    }
} */

.carousel-section .tech-carousel .tech-name {
    font-family: 'Poppins', sans-serif !important;
    font-weight: 400 !important;
    font-size: 2rem;
    color: white;
}

.carousel-section .tech-carousel .tech-image {
    width: 75%;
}

/* @media screen and (max-width:768px) {
    .carousel-section .tech-carousel .tech-image {
        width: 50%;
    }
} */

@media screen and (max-width:480px) {
    .carousel-section .tech-carousel .tech-name {
        font-size: 4rem;
    }
}

.carousel-section .tech-carousel .slick-prev:before,
.carousel-section .tech-carousel .slick-next:before {
    font-family: 'slick';
    font-size: 2rem;
    line-height: 0.1rem;
    opacity: .75;
    color: black;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}


.carousel-section .tech-carousel .slick-prev:before {
    position: absolute;
    margin-left: -1rem !important;
    margin-top: 0rem !important;
}

.carousel-section .tech-carousel .slick-next:before {
    position: absolute;
    margin-left: -1rem !important;
    margin-top: 0rem !important;
}

.who-are-we {
    /* background-image: linear-gradient(rgba(225, 85, 84, 1), rgba(225, 85, 84, 1), rgba(225, 85, 84, 1), white); */
    background-image: linear-gradient(rgba(225, 85, 84, 1), rgba(225, 85, 84, 1), rgba(225, 85, 84, 1), #E9B5B5, #E9B5B5, white);
    height: 55rem;
    padding: 2rem;
    color: white;
    width: 100%;
    margin-top: -10.5%;
    position: relative;
    z-index: -1;
}

@media screen and (max-width:480px) {
    .who-are-we {
        margin-top: -35%;
        height: 68rem;
    }
}

.who-are-we div {
    margin-top: 12rem !important;
}

@media screen and (max-width:480px) {
    .who-are-we div {
        margin-top: 30% !important;
    }
}

.who-are-we div h1 {
    font-family: 'Poppins', sans-serif !important;
    font-weight: 700 !important;
    font-size: 5rem;
    margin-left: 5rem;
    margin-top: 6rem;
}

.who-are-we div p {
    font-family: 'Poppins', sans-serif !important;
    font-weight: 500 !important;
    font-size: 1.8rem;
    width: 50rem;
    color: black;
    line-height: 2.4rem;
    margin-top: 5rem;
    margin-left: 5rem;
    margin-bottom: 2rem;
}

@media screen and (max-width:480px) {
    /* .who-are-we {
        height: 65rem;
    } */
    .who-are-we div p {
        font-size: 2.2rem;
        width: 70%;
    }
}

@media screen and (max-width:285px) {
    .who-are-we div h1 {
        margin-left: 5%;
    }
    .who-are-we div p {
        font-size: 2.2rem;
        margin-left: 5%;
    }
}

.who-are-we-1 button{
    margin-top: -15rem;
    margin-left: 7rem;
}

.who-are-we img{
    margin-top: 2rem;
    height: 60%;
}

/* .who-are-we img{
    width: 40rem;
} */

.counter-main{
    /* background-color: rgba(171, 171, 171, 0.85); */
    /* height: 15rem; */
    background-image: url('../Images/LaptopImageCounter.png');
    position: relative;
    padding: 3rem 3rem 2rem 3rem;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
}

.counter-main img {
    height: 8rem;
}

.counter-main h1{
    font-family: 'Poppins', sans-serif !important;
    font-weight: 700 !important;
    font-size: 4rem;
    /* margin-top: 3rem; */
    text-align: center;
    color: white;
}

.counter-main p{
    font-family: 'Poppins', sans-serif !important;
    font-weight: 400 !important;
    font-size: 2rem;
    color: white;
}

@media screen and (max-width:481px) {
    .counter-main h1{
        font-size: 4.5rem;
    }
    
    .counter-main p{
        font-size: 2.2rem;
    }
}

.Home-main .carousel-control-prev .carousel-control-prev-icon {
    background-color: black;
    margin-left: -10rem;
}

.Home-main .carousel-control-next .carousel-control-next-icon {
    background-color: black;
    margin-left: 10rem;
}

@media screen and (max-width:478px) {
    .Home-main .carousel-control-prev .carousel-control-prev-icon {
        margin-left: -7rem;
    }
    
    .Home-main .carousel-control-next .carousel-control-next-icon {
        margin-left: 7rem;
    }
}