.about-us-main {
  margin-top: 1rem;
  width: 100%;
  /* position: relative; */
  overflow: hidden;
}

.about-us-main .our-description h1 {
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size: 5rem !important;
  color: rgba(118, 118, 118, 1);
  position: relative;
  top: 3rem;
}

.section-vision {
  position: relative;
  top: 5rem;
}

.section-vision .philosophy-heading {
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 2.8rem !important;
}

.section-vision .philosophy-content div {
  width: 66rem;
  height: 58rem;
  background-color: rgba(230, 230, 230, 1);
  z-index: 3;
  border-radius: 10%;
}

.section-vision .philosophy-content div p {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 1.5rem !important;
  width: 44rem;
  margin-top: 5rem;
  margin-left: 10rem;
}

.section-vision .vision {
  width: 85rem;
  position: absolute;
  top: 25rem;
}

.vision-content {
  /* position: absolute; */
  position: relative;
  width: 66rem;
  height: 37rem;
  /* background-color: rgba(241, 241, 241, 1); */
  /* z-index: 5; */
  top: 0rem;
}

.vision-content .background-color-div {
  width: 100%;
  height: 100%;
  background-color: rgba(241, 241, 241, 1);
  z-index: -2;
}

.vision-content .outer-circle {
  width: 15rem;
  height: 15rem;
  border-radius: 50%;
  border: 1px solid rgba(173, 173, 173, 1);
  background-color: white;
  position: absolute;
  top: 3rem;
  left: 12rem;
  z-index: 6;
}

.vision-content .outer-circle .inner-circle {
  width: 13rem;
  height: 13rem;
  border-radius: 50%;
  background-color: rgba(225, 85, 84, 1);
  position: absolute;
  left: 1rem;
  top: 1rem;
}

.vision-content .outer-circle .inner-circle img {
  margin-left: -0.5rem;
  margin-top: 1.5rem;
}

.actual-vision-content {
  width: 70rem;
  height: 14rem;
  position: absolute;
  background-color: white;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  /* margin-left: 29rem;
    margin-top: -34rem; */
  top: 3.5rem;
  left: 22rem;
  z-index: 5;
  border-top-right-radius: 7rem;
  border-bottom-right-radius: 7rem;
}

.actual-vision-content p {
  width: 60rem;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 1.2rem !important;
  margin-top: 2rem;
  margin-left: 7rem;
}

/* Mission Content */

/* .mission-content{
    position: absolute;
    width: 66rem;
    height: 35rem;
    background-color: rgba(241, 241, 241, 1);
    z-index: -1;
    top: 15rem;
} */

.mission-content .outer-circle {
  width: 15rem;
  height: 15rem;
  border-radius: 50%;
  background-color: white;
  border: 1px solid rgba(173, 173, 173, 1);
  position: absolute;
  top: 20rem;
  left: 105rem;
  z-index: 6;
}

.mission-content .outer-circle .inner-circle {
  width: 13rem;
  height: 13rem;
  border-radius: 50%;
  background-color: rgba(225, 85, 84, 1);
  position: absolute;
  left: 1rem;
  top: 1rem;
}

.mission-content .outer-circle .inner-circle img {
  margin-left: 0.5rem;
  margin-top: 0rem;
}

.actual-mission-content {
  width: 70rem;
  height: 14rem;
  position: absolute;
  background-color: white;
  /* margin-left: 29rem;
    margin-top: -34rem; */
  top: 20.5rem;
  left: 42rem;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  z-index: 5;
  border-top-left-radius: 7rem;
  border-bottom-left-radius: 7rem;
}

.actual-mission-content p {
  width: 60rem;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 1.2rem !important;
  margin-top: 2.5rem;
  margin-left: 3rem;
}

/* .about-us-main .team {
  margin-top: 10rem;
} */

.about-us-main .team .main-heading {
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size: 5rem !important;
  color: rgba(118, 118, 118, 1);
}

.management-team h1,
.training-team h1,
.development-team h1,
.design-team h1 {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size: 2.5rem !important;
  text-align: center;
  color: rgba(70, 70, 70, 1);
}

.management-team .team-cards,
.training-team .team-cards,
.development-team .team-cards,
.design-team .team-cards {
  /* display: grid;
  grid-template-columns: repeat(4, 1fr); */
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 1rem;
  /* margin-left: 1rem; */
  /* padding: 0rem 1rem; */
  width: 100%;
  margin-top: 0rem;
}

.management-team .team-cards .main-div,
.training-team .team-cards .main-div,
.development-team .team-cards .main-div,
.design-team .team-cards .main-div {
  width: 25rem;
  height: 30.5rem;
  color: white;
  margin-top: 2rem;
  /* padding: 1rem; */
  border: 10px solid rgba(223, 223, 223, 1);
  /* border: 12px solid #F9F9F9; */
  /* background-color: rgba(223, 223, 223, 1); */
  /* background-color: white; */
}

@media screen and (max-width:768px) {

  .management-team .team-cards .main-div,
  .training-team .team-cards .main-div,
  .development-team .team-cards .main-div,
  .design-team .team-cards .main-div {
    height: 32rem;
  }

  .management-team .row-1 .column-0 {
    background: linear-gradient(#EEEDED 40%, hsl(4, 100%, 62%) 70%) !important;
  }

  .management-team .row-1 .column-1 {
    background: linear-gradient(#EEEDED 40%, hsl(4, 67%, 62%) 70%) !important;
  }

  .management-team .row-1 .column-2 {
    background: linear-gradient(#EEEDED 40%, hsl(4, 34%, 62%) 70%) !important;
  }

  .management-team .row-2 .column-0 {
    background: linear-gradient(#EEEDED 40%, hsl(4, 34%, 62%) 70%) !important;
  }

  .management-team .row-2 .column-1 {
    background: linear-gradient(#EEEDED 40%, hsl(4, 67%, 62%) 70%) !important;
  }

  .management-team .row-2 .column-2 {
    background: linear-gradient(#EEEDED 40%, hsl(4, 100%, 62%) 70%) !important;
  }

  .training-team .row-1 .column-0 {
    background: linear-gradient(#F9F9F9 40%, hsl(15, 100%, 62%) 70%) !important;
  }

  .training-team .row-1 .column-1 {
    background: linear-gradient(#F9F9F9 40%, hsl(15, 67%, 62%) 70%) !important;
  }

  .training-team .row-1 .column-2 {
    background: linear-gradient(#F9F9F9 40%, hsl(15, 34%, 62%) 70%) !important;
  }

  .training-team .row-2 .column-0 {
    background: linear-gradient(#F9F9F9 40%, hsl(15, 34%, 62%) 70%) !important;
  }

  .training-team .row-2 .column-1 {
    background: linear-gradient(#F9F9F9 40%, hsl(15, 67%, 62%) 70%) !important;
  }

  .training-team .row-2 .column-2 {
    background: linear-gradient(#F9F9F9 40%, hsl(15, 100%, 62%) 70%) !important;
  }

  .development-team .row-1 .column-0 {
    background: linear-gradient(#F9F9F9 0%, hsl(154, 76%, 63%) 10%, hsl(204, 50%, 45%) 90%);
  }
  
  .development-team .row-1 .column-1 {
    background: linear-gradient(#F9F9F9 0%, hsl(154, 76%, 63%) 10%, hsl(204, 40%, 45%) 90%);
  }
  
  .development-team .row-1 .column-2 {
    background: linear-gradient(#F9F9F9 0%, hsl(154, 76%, 63%) 10%, hsl(204, 30%, 45%) 90%);
  }

  .development-team .row-2 .column-0 {
    background: linear-gradient(#F9F9F9 0%, hsl(154, 76%, 63%) 10%, hsl(204, 30%, 45%) 90%);
  }
  
  .development-team .row-2 .column-1 {
    background: linear-gradient(#F9F9F9 0%, hsl(154, 76%, 63%) 10%, hsl(204, 40%, 45%) 90%);
  }
  
  .development-team .row-2 .column-2 {
    background: linear-gradient(#F9F9F9 0%, hsl(154, 76%, 63%) 10%, hsl(204, 50%, 45%) 90%);
  }
}



@media screen and (max-width:480px) {

  .management-team .team-cards .main-div,
  .training-team .team-cards .main-div,
  .development-team .team-cards .main-div,
  .design-team .team-cards .main-div {
    height: 33rem;
    width: 30rem;
  }

  .management-team .row-1 .column-0 {
    background: linear-gradient(#EEEDED 40%, hsl(4, 100%, 62%) 70%) !important;
  }

  .management-team .row-1 .column-1 {
    background: linear-gradient(#EEEDED 40%, hsl(4, 50%, 62%) 70%) !important;
  }

  .management-team .row-2 .column-0 {
    background: linear-gradient(#EEEDED 40%, hsl(4, 50%, 62%) 70%) !important;
  }

  .management-team .row-2 .column-1 {
    background: linear-gradient(#EEEDED 40%, hsl(4, 100%, 62%) 70%) !important;
  }

  .training-team .row-1 .column-0 {
    background: linear-gradient(#F9F9F9 40%, hsl(15, 100%, 62%) 70%) !important;
  }

  .training-team .row-1 .column-1 {
    background: linear-gradient(#F9F9F9 40%, hsl(15, 50%, 62%) 70%) !important;
  }

  .training-team .row-2 .column-0 {
    background: linear-gradient(#F9F9F9 40%, hsl(15, 50%, 62%) 70%) !important;
  }

  .training-team .row-2 .column-1 {
    background: linear-gradient(#F9F9F9 40%, hsl(15, 100%, 62%) 70%) !important;
  }

  .development-team .row-1 .column-0 {
    background: linear-gradient(#F9F9F9 0%, hsl(154, 76%, 63%) 10%, hsl(204, 50%, 45%) 90%);
  }
  
  .development-team .row-1 .column-1 {
    background: linear-gradient(#F9F9F9 0%, hsl(154, 76%, 63%) 10%, hsl(204, 40%, 45%) 90%);
  }

  .development-team .row-2 .column-0 {
    background: linear-gradient(#F9F9F9 0%, hsl(154, 76%, 63%) 10%, hsl(204, 40%, 45%) 90%);
  }
  
  .development-team .row-2 .column-1 {
    background: linear-gradient(#F9F9F9 0%, hsl(154, 76%, 63%) 10%, hsl(204, 50%, 45%) 90%);
  }
}


/*Management team color starts*/
.management-team .row-1 .column-0 {
  background: linear-gradient(#EEEDED 40%, hsl(4, 100%, 62%) 70%);
}

.management-team .row-1 .column-1 {
  background: linear-gradient(#EEEDED 40%, hsl(4, 75%, 62%) 70%);
}

.management-team .row-1 .column-2 {
  background: linear-gradient(#EEEDED 40%, hsl(4, 50%, 62%) 70%);
}

.management-team .row-1 .column-3 {
  background: linear-gradient(#EEEDED 40%, hsl(4, 25%, 62%) 70%);
}

.management-team .row-2 .column-3 {
  background: linear-gradient(#EEEDED 40%, hsl(4, 100%, 62%) 70%);
}

.management-team .row-2 .column-2 {
  background: linear-gradient(#EEEDED 40%, hsl(4, 75%, 62%) 70%);
}

.management-team .row-2 .column-1 {
  background: linear-gradient(#EEEDED 40%, hsl(4, 50%, 62%) 70%);
}

.management-team .row-2 .column-0 {
  background: linear-gradient(#EEEDED 40%, hsl(4, 25%, 62%) 70%);
}
/*Management team color ends*/



/*Training team color starts*/
.training-team .row-1 .column-0 {
  background: linear-gradient(#F9F9F9 20%, hsl(15, 100%, 62%) 70%);
}

.training-team .row-1 .column-1 {
  background: linear-gradient(#F9F9F9 20%, hsl(15, 75%, 62%) 70%);
}

.training-team .row-1 .column-2 {
  background: linear-gradient(#F9F9F9 20%, hsl(15, 50%, 62%) 70%);
}

.training-team .row-1 .column-3 {
  background: linear-gradient(#F9F9F9 20%, hsl(15, 25%, 62%) 70%);
}

.training-team .row-2 .column-3 {
  background: linear-gradient(#F9F9F9 20%, hsl(15, 100%, 62%) 70%);
}

.training-team .row-2 .column-2 {
  background: linear-gradient(#F9F9F9 20%, hsl(15, 75%, 62%) 70%);
}

.training-team .row-2 .column-1 {
  background: linear-gradient(#F9F9F9 20%, hsl(15, 50%, 62%) 70%);
}

.training-team .row-2 .column-0 {
  background: linear-gradient(#F9F9F9 20%, hsl(15, 25%, 62%) 70%);
}
/*Training team color ends*/



/*Development team color starts*/
.development-team .row-1 .column-0 {
  background: linear-gradient(#F9F9F9 20%, hsl(154, 76%, 63%) 50%, hsl(204, 50%, 45%) 90%);
}

.development-team .row-1 .column-1 {
  background: linear-gradient(#F9F9F9 20%, hsl(154, 76%, 63%) 50%, hsl(204, 40%, 45%) 90%);
}

.development-team .row-1 .column-2 {
  background: linear-gradient(#F9F9F9 20%, hsl(154, 76%, 63%) 50%, hsl(204, 30%, 45%) 90%);
}

.development-team .row-1 .column-3 {
  background: linear-gradient(#F9F9F9 20%, hsl(154, 76%, 63%) 50%, hsl(204, 10%, 45%) 90%);
}

.development-team .row-2 .column-0 {
  background: linear-gradient(#F9F9F9 20%, hsl(154, 76%, 63%) 50%, hsl(204, 10%, 45%) 90%);
}

.development-team .row-2 .column-1 {
  background: linear-gradient(#F9F9F9 20%, hsl(154, 76%, 63%) 50%, hsl(204, 30%, 45%) 90%);
}

.development-team .row-2 .column-2 {
  background: linear-gradient(#F9F9F9 20%, hsl(154, 76%, 63%) 50%, hsl(204, 40%, 45%) 90%);
}

.development-team .row-2 .column-3 {
  background: linear-gradient(#F9F9F9 20%, hsl(154, 76%, 63%) 50%, hsl(204, 50%, 45%) 90%);
}
/*Development team color ends*/


/*Design team color starts*/
.design-team .row-1 .column-0 {
  background: linear-gradient(#EEEDED 20%, hsl(179, 54%, 70%) 50%, hsl(272, 35%, 69%) 90%);
}

.design-team .row-1 .column-1 {
  background: linear-gradient(#EEEDED 20%, hsl(179, 54%, 70%) 50%, hsl(272, 35%, 69%) 90%);
}

.design-team .row-1 .column-2 {
  background: linear-gradient(#EEEDED 20%, hsl(179, 54%, 70%) 50%, hsl(272, 35%, 69%) 90%);
}

.design-team .row-1 .column-3 {
  background: linear-gradient(#EEEDED 20%, hsl(179, 54%, 70%) 50%, hsl(272, 35%, 69%) 90%);
}

.design-team .row-2 .column-3 {
  background: linear-gradient(#EEEDED 20%, hsl(179, 54%, 70%) 50%, hsl(272, 35%, 69%) 90%);
}

.design-team .row-2 .column-2 {
  background: linear-gradient(#EEEDED 20%, hsl(179, 54%, 70%) 50%, hsl(272, 35%, 69%) 90%);
}

.design-team .row-2 .column-1 {
  background: linear-gradient(#EEEDED 20%, hsl(179, 54%, 70%) 50%, hsl(272, 35%, 69%) 90%);
}

.design-team .row-2 .column-0 {
  background: linear-gradient(#EEEDED 20%, hsl(179, 54%, 70%) 50%, hsl(272, 35%, 69%) 90%);
}
/*Design team color ends*/



/* .management-team .team-cards .main-div div {
  background-color: white !important;
  height: 25rem;
} */

.training-team {
  margin-top: 6rem;
}

.development-team {
  margin-top: 6rem;
}

.design-team {
  margin-top: 6rem;
}

.management-team .team-cards .main-div div img,
.training-team .team-cards .main-div div img,
.development-team .team-cards .main-div div img,
.design-team .team-cards .main-div div img {
  width: 100%;
  height: 22rem;
  padding: 0.5rem;
}

.management-team .team-cards .main-div .fname,
.training-team .team-cards .main-div .fname,
.development-team .team-cards .main-div .fname,
.design-team .team-cards .main-div .fname {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 2rem !important;
  text-align: center;
}

.management-team .team-cards .main-div .designation,
.training-team .team-cards .main-div .designation,
.development-team .team-cards .main-div .designation,
.design-team .team-cards .main-div .designation {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 1.5rem !important;
  text-transform: uppercase;
  text-align: center;
}



/* Training Team css starts below */

/* .training-team h1 {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size: 2.5rem !important;
  text-align: center;
  color: rgba(70, 70, 70, 1);
}

.training-team .team-cards {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  margin-bottom: 3rem;
  margin-left: 2rem;
  padding: 1rem;
  width: 98%;
  margin-top: 0rem;
}

.training-team .team-cards .main-div {
  width: 25rem;
  height: 32rem;
  margin-top: 2rem;
  padding: 1rem;
  background-color: rgba(223, 223, 223, 1);
}

.training-team .team-cards .main-div div {
  background-color: white !important;
}

.training-team .team-cards .main-div div img {
  width: 100%;
  height: 23rem;
  padding: 1rem;
}

.training-team .team-cards .main-div .fname {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 2rem !important;
  text-align: center;
}

.training-team .team-cards .main-div .designation {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 1.5rem !important;
  text-transform: uppercase;
  text-align: center;
} */

/* Training team Main-Head css starts below */

/* .training-team .main-head {
  width: 32rem;
  height: 32rem;
  margin-top: 2rem;
  padding: 1.2rem;
  border-radius: 50%;
  background-color: rgba(223, 223, 223, 1);
}

.training-team .main-head div {
  background-color: white !important;
  border-radius: 50%;
}

.training-team .main-head div img {
  width: 23rem;
  height: 23rem;
  border-radius: 50%;
  margin-left: 3.2rem;
  padding: 1rem;
}

.training-team .main-head .fname {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 2rem !important;
  text-align: center;
}

.training-team .main-head .designation {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 1.5rem !important;
  text-transform: uppercase;
  text-align: center;
} */



/* Development team Main-Head css starts below */

/* .development-team .main-head {
  width: 32rem;
  height: 32rem;
  margin-top: 2rem;
  padding: 1.2rem;
  border-radius: 50%;
  background-color: rgba(223, 223, 223, 1);
}

.development-team .main-head div {
  background-color: white !important;
  border-radius: 50%;
}

.development-team .main-head div img {
  width: 23rem;
  height: 23rem;
  border-radius: 50%;
  margin-left: 3.2rem;
  padding: 1rem;
}

.development-team .main-head .fname {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 2rem !important;
  text-align: center;
}

.development-team .main-head .designation {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 1.5rem !important;
  text-transform: uppercase;
  text-align: center;
} */

/* Development Team css starts below */

/* .development-team h1 {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size: 2.5rem !important;
  text-align: center;
  color: rgba(70, 70, 70, 1);
}

.development-team .team-cards {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  margin-bottom: 3rem;
  margin-left: 2rem;
  padding: 1rem;
  width: 98%;
  margin-top: 0rem;
}

.development-team .team-cards .main-div {
  width: 25rem;
  height: 32rem;
  margin-top: 2rem;
  padding: 1rem;
  background-color: rgba(223, 223, 223, 1);
}

.development-team .team-cards .main-div div {
  background-color: white !important;
}

.development-team .team-cards .main-div div img {
  width: 100%;
  height: 23rem;
  padding: 1rem;
}

.development-team .team-cards .main-div .fname {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 2rem !important;
  text-align: center;
}

.development-team .team-cards .main-div .designation {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 1.5rem !important;
  text-transform: uppercase;
  text-align: center;
} */

/* Currently not in use */

/* @media screen and (max-width: 768px) {
  .management-team .team-cards {
    grid-template-columns: repeat(3, 1fr);
  }

  .training-team .team-cards {
    grid-template-columns: repeat(3, 1fr);
  }

  .development-team .team-cards {
    grid-template-columns: repeat(3, 1fr);
  }
} */

/* Currently not in use */


/* Acievement Section Starts */

.about-us-main .achievement-section .achievement-heading {
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size: 5rem !important;
  color: rgba(118, 118, 118, 1);
  margin-top: 5rem;
}

.about-us-main .achievement-section .achievement-carousel-main {
  width: 90%;
  margin-left: 7rem;
  margin-top: 5rem;
}

.about-us-main .achievement-section .achievement-carousel-main img {
  height: 35rem;
  width: 34rem;
}

.about-us-main .achievement-section .achievement-carousel-main .slick-prev:before,
.about-us-main .achievement-section .achievement-carousel-main .slick-next:before {
  font-family: "slick";
  font-size: 2rem;
  line-height: 0.1rem;
  opacity: 0.75;
  color: black;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.about-us-main .achievement-section .achievement-carousel-main .slick-prev:before {
  position: absolute;
  margin-left: -3rem !important;
  margin-top: 0rem !important;
}

.about-us-main .achievement-section .achievement-carousel-main .slick-next:before {
  position: absolute;
  margin-left: 0rem !important;
  margin-top: 0rem !important;
}

@media screen and (max-width:768px) {
  .about-us-main .achievement-section .achievement-carousel-main .slick-prev:before {
    margin-left: -1rem !important;
    margin-top: 0rem !important;
  }

  .about-us-main .achievement-section .achievement-carousel-main .slick-next:before {
    margin-left: -2rem !important;
    margin-top: 0rem !important;
  }
}

@media screen and (max-width:480px) {
  .about-us-main .achievement-section .achievement-carousel-main .slick-prev:before {
    margin-left: -1rem !important;
    margin-top: 0rem !important;
  }

  .about-us-main .achievement-section .achievement-carousel-main .slick-next:before {
    margin-left: -3rem !important;
    margin-top: 0rem !important;
  }
}

/*Sumago in Media section starts */

.about-us-main .media-section {
  margin-top: 8rem;
  margin-bottom: 5rem;
}

.about-us-main .media-section .media-heading {
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size: 5rem !important;
  color: rgba(118, 118, 118, 1);
  margin-top: 5rem;
}

.about-us-main .media-section .media-carousel-main {
  width: 90%;
  margin-left: 7rem;
  margin-top: 5rem;
}

.about-us-main .media-section .media-carousel-main img {
  height: 66rem;
  /* width: 34rem; */
}

.about-us-main .media-section .media-carousel-main .slick-prev:before,
.about-us-main .media-section .media-carousel-main .slick-next:before {
  font-family: "slick";
  font-size: 2rem;
  line-height: 0.1rem;
  opacity: 0.75;
  color: black;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.about-us-main .media-section .media-carousel-main .slick-prev:before {
  position: absolute;
  margin-left: -3rem !important;
  margin-top: 0rem !important;
}

.about-us-main .media-section .media-carousel-main .slick-next:before {
  position: absolute;
  margin-left: 0rem !important;
  margin-top: 0rem !important;
}


/* About-us-horizontal-banner */

.circleShape {
  position: relative;
  left: -7rem;
  top: 2.5rem;
}

.circleShape img {
  width: 150%;
}


/* .aboutsubMainCard3{
  position: relative;
  right: 100px;
} */
.abcimg {
  position: relative;
  width: 80rem;
  /* height: 66.2rem; */
  height: 92.6rem;
}

@media screen and (min-width:1400px) {
  .abcimg {
    height: 65.7rem !important;
  }
}

.aboutsubMainCard {
  background-color: #d31c23;
  position: relative;
  /* left: 61.3rem; */
  /* top: 75.4rem; */
  z-index: 1;
  /* width: 55%; */
}

.aboutsubMainCard1 {
  background-color: #797979;
  position: relative;
  /* left: 61.3rem; */
  /* top: 78.2rem; */
  z-index: 1;
  /* width: 55%; */
}

.aboutsubMainCard2 {
  background-color: #acacac;
  position: relative;
  /* left: 61.3rem; */
  /* top: 80.8rem; */
  z-index: 1;
  /* width: 55%; */
}

@media screen and (max-width: 767px) {
  .circleShape img {
    width: 40% !important;
    margin-left: 35%;
  }
}

@media screen and (min-width: 767px) and (max-width: 991px) {
  .circleShape img {
    width: 80% !important;
    margin-left: 30%;
  }
}