* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

#navbar-flex-id .git-button a {
    padding: 0;
    color: #ffffff;
}

#navbar-flex-id .git-button button {
    border-radius: 1.7rem;
}

#navbar-flex-id a {
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    padding: 1.5rem 2rem;
    font-size: 1.75rem !important;
    color: black;
    /* border-left: 1px solid rgba(176, 176, 176, 1); */
}

#navbar-flex-id span {
    font-family: 'Poppins', sans-serif;
    /* padding: 1.5rem 2rem; */
    font-weight: normal !important;
    font-size: 1.65rem !important;
    color: black;
}

#navbar-flex-id #span12 {
    margin-top: -1.3rem;
}

#navbar-flex-id span svg{
    font-size: 1.5rem !important;
}

#navbar-flex-id .git-main-button {
    background: linear-gradient(to right, #E15554 0%, #E15554 45%, rgba(137, 137, 137, .75) 97%, #817A7A 100%);
}

@media screen and (max-width:480px) {
    #navbar-flex-id a, #navbar-flex-id span {
        font-size: 2.5rem !important;
    }
}

/* .header-strip {
    background-color: #E15554;
    width: 30rem;
    height: 8.3rem;
    margin-top: 1.2rem;
    border-top-right-radius: 5.15rem;
    border-bottom-right-radius: 5.15rem;
} */

/* .company_logo img {
    width: 70rem;
    height: 6rem;
    margin-top: 1rem;
    margin-left: 68%;
} */

.header-strip {
    background-color: #E15554;
    padding: 1rem 0 1rem 0;
    width: 100%;
}

.header-strip .new-logo {
    padding: 1rem;
    background-color: white;
    width: 50%;
    border-radius: 4rem;
}

.header-strip .new-logo img {
    height: 5rem;
}

/* @media screen and (max-width:653px) {
    .header-strip {
        width: 20rem;
        height: 8.3rem;
        margin-top: 1.2rem;
        border-top-right-radius: 5.15rem;
        border-bottom-right-radius: 5.15rem;
    }

    .company_logo img {
        width: 48rem;
        height: 6rem;
        margin-top: 1rem;
        margin-left: 68%;
    }
} */

.navbar-flex a {
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 1.5rem !important;
    /* width: 13.4rem; */
}

/* ul{
    list-style-type: none;
} */

.navbar-flex {
    width: 100%;
}

/* .nav-home{
    color: #D31E24;
} */

/* .nav-phone{
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 1.5rem !important;
    margin-left: 9rem;
} */

/* .phone-icon{
    font-size: 2.5rem !important;
    color: #D31E24;
} */