/* This is css for NewLifeComp Component */

.life-main-new {
    overflow: hidden;
}

.life-main-new .fun .carousel-main img {
    height: 64.5rem;
}
.samecomp{
    height: 64.5rem;
}
@media screen and (max-width:490px) {
    .life-main-new .fun .carousel-main img {
        height: 34.5rem !important;
    }
    .samecomp{
        height: 34.5rem !important;
    }
    
}
@media screen and (min-width:490px) and (max-width:720px) {
    .life-main-new .fun .carousel-main img {
        height: 45.5rem !important;
    }
    .samecomp{
        height: 45.5rem !important;
    }
    
}
.life-main-new .rec .carousel-main img {
    height: 66.5rem;
    margin-top: 1.6rem;
}

.life-main-new .news .carousel-main img {
    height: 66.2rem;
}