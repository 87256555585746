.LandingPage-main {
  height: 100%;
  width: 100%;
}

.LandingPage-main .banner-main {
  width: 100%;
  height: 100%;
  /* background-color: #f6f6f6; */
}

.LandingPage-main .banner-main .banner-background {
  width: 100%;
  height: 58%;
  position: absolute;
}

@media screen and (max-width:768px) {
  .LandingPage-main .banner-main .banner-background {
    width: 50%;
    height: 100%;
  }
}

.LandingPage-main .banner-main .banner-background img {
  width: 100%;
  height: 100%;
  z-index: -1;
}

.LandingPage-main .banner-main .webandtraining {
  width: 100%;
  height: 100%;
  padding: 4rem;
  /* padding: 2.5rem; */
}

.LandingPage-main .banner-main .webandtraining img {
  /* height: 100%; */
  /* height: 60%; */
  z-index: 1;
  cursor: pointer;
}

/*New code for flip animation starts*/

.flip-card {
  perspective: 1000px;
  width: 30rem;
  height: 30rem;
  margin-top: 25rem;
}

@media screen and (max-width:768px) {
  .flip-card {
    margin-top: 0rem;
  }
  
  .flip-card-2 {
    margin-top: 12rem !important;
  }
}

@media screen and (max-width:480px) {
  .flip-card {
    margin-top: 35%;
  }
  
  .flip-card-2 {
    margin-top: 15rem !important;
  }
}

.flip-card-inner {
  width: 100%;
  height: 100%;
  transition: transform 0.5s;
  transform-style: preserve-3d;
  position: relative;
}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  position: absolute;
}

.flip-card-front {
  /* background-color: #ccc; */
  border-radius: 50%;
  transform: rotateY(0deg);
}

.flip-card-back {
  /* background-color: black; */
  color: white;
  border-radius: 50%;
  transform: rotateY(180deg);
}

/*New code for flip animation ends*/