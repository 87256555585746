.NewFooter-main {
    width: 100%;
    height: 100%;
    background-color: #525252;
    position: relative;
}

.NewFooter-main .footer-section {
    color: white;
    padding: 5rem 2rem 1rem 2rem;
    /* margin-left: 5rem; */
}

.NewFooter-main .footer-section .first-row-main {
    padding-bottom: 5rem;
}

.NewFooter-main .footer-section .first-row-main h1{
    text-transform: uppercase;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 3rem;
}

.NewFooter-main .footer-section .first-row-main p{
    font-family: 'Poppins', sans-serif;
    font-weight: 200;
    font-size: 1.5rem;
}

.NewFooter-main .footer-section .our-office h1 {
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 3rem;
}

.NewFooter-main .footer-section .second-row-main div {
    /* padding: 2rem; */
    width: 32%;
}

@media screen and (max-width:864px) {
    .NewFooter-main .footer-section .second-row-main div {
        padding-right: 2rem;
        width: 100%;
    }
}

.NewFooter-main .footer-section .second-row-main div .address {
    height: 8rem;
}

/* .NewFooter-main .footer-section .second-row-main div .email-0 {
    margin-top: 3rem;
}

.NewFooter-main .footer-section .second-row-main div .email-1 {
    margin-top: 5rem;
}

.NewFooter-main .footer-section .second-row-main div .email-2 {
    margin-top: 5rem;
} */

.NewFooter-main .footer-section .second-row-main .location-main {
    border: .1rem solid white;
    padding: 2rem 2rem 2rem 1rem;
    border-radius: 1.5rem;
}

.NewFooter-main .footer-section .second-row-main h1{
    text-transform: uppercase;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 2.6rem;
}

.NewFooter-main .footer-section .second-row-main p{
    font-family: 'Poppins', sans-serif;
    font-weight: 200;
    font-size: 1.5rem;
}

.NewFooter-main .footer-section .second-row-main img{
    height: 5rem;
}

.NewFooter-main .footer-section .with-love-section {
    margin-top: 7rem;
}

.NewFooter-main .footer-section .with-love-section h2 {
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 1.5rem;
}

.NewFooter-main .footer-section .with-love-section svg {
    font-size: 3rem;
}

.NewFooter-main .footer-section .copyright-section h5 {
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 1.2rem;
    margin-top: -3rem;
}

@media screen and (max-width:480px) {
    .NewFooter-main .footer-section .second-row-main p{
        font-size: 2.5rem !important;
    }

    .NewFooter-main .footer-section .first-row-main p{
        font-size: 2.5rem !important;
    }
}

@media screen and (max-width:768px) {

    .NewFooter-main .footer-section .second-row-main h1{
        font-size: 4rem;
    }

    .NewFooter-main .footer-section .first-row-main h1{
        font-size: 4rem;
    }

    .NewFooter-main .footer-section .second-row-main p{
        font-size: 2rem;
    }

    .NewFooter-main .footer-section .first-row-main p{
        font-size: 2rem;
    }
}