.service-img-part {
    position: relative;
    padding: 5rem;
    /* background-image: url('../Images/ServiceImage.png'); */
    background-image: url('../Images/BACKGROUNDForHomeService.png');
}

.service-img-part .home-service-carousel {
    width: 100%;
    /* margin-left: 5%; */
}

.service-img-part .home-service-carousel .heading h1 {
    color: white;
    padding-bottom: 2rem;
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    font-size: 5rem;
}

.service-img-part .home-service-carousel img{
    height: 65rem;
    width: 70%;
}

.service-img-part .home-service-carousel .slick-prev:before,
.service-img-part .home-service-carousel .slick-next:before {
    font-family: 'slick';
    font-size: 3rem;
    line-height: 0.1rem;
    opacity: .75;
    color: white;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}


.service-img-part .home-service-carousel .slick-prev:before {
    position: absolute;
    margin-left: -2rem !important;
    margin-top: 0rem !important;
}

.service-img-part .home-service-carousel .slick-next:before {
    position: absolute;
    margin-left: -1rem !important;
    margin-top: 0rem !important;
}